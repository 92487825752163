import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { FiClock } from "react-icons/fi"; // Import FiHeart and FiClock icons
import { FaBookmark } from 'react-icons/fa';
import { CiLocationOn } from "react-icons/ci"; // Import CiLocationOn icon
import "./FeaturedProducts.css";
import "./ProductList.css"
import { Link, useParams } from "react-router-dom";

// Static data for laptop types and brands
const laptopTypes = ["Gaming", "Business", "Student"];


// Define the truncateDescription function if it's not defined
const truncateDescription = (description) => {
  if (description.length > 50) {
    return `${description.substring(0, 50)}...`;
  }
  return description;
};

function ProductList() {
  const { categoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [categoryNotFound, setCategoryNotFound] = useState(false);
  const [categories, setCategories] = useState([]);
  const [showAllCategories, setShowAllCategories] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `https://fashionapi.codingster.in/Products?area=null&category=null`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setProducts(data);
        setCategoryNotFound(false); // Reset category not found flag
      } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([]); // Set products to an empty array if error occurs
        setCategoryNotFound(true); // Set category not found flag
      }
    };

    fetchProducts();
  }, [categoryId]);

  useEffect(() => {
    fetch("https://fashionapi.codingster.in/List_Category")
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error("Error fetching categories:", error));
  }, []);

  return (
    <div className="featured-products">
      <Container fluid>
        <Row>
          <Col md={9}>
            {categoryNotFound ? (
              <p>No products available for this category.</p>
            ) : (
              <Row id="products">
                {products.map((item, index) => (
                  <Col md={4} key={index} className="mb-4">
                    <Link to={`/productslist?productid=${item.id}`} className="product-item text-decoration-none text-center">
                      <div className="custom-card product-card">
                        <img src={item.display_image} alt="Card Image" />
                        <div className="card-content">
                          {item.discount > 0 && (
                            <p className="productdiscount">{item.discount}% OFF</p>
                          )}
                          <div className="card-description">
                            <div className='d-flex justify-content-between'>
                              <p className='categoryame text-uppercase'>{item.categoryname}</p>
                              <p className='clock'><FiClock />1 Day ago</p>
                            </div>
                            <div className='productname'>
                              <p>{item.name}</p>
                            </div>
                            <div className='prductdata'>
                              <p>{truncateDescription(item.description)}</p>
                            </div>
                            <div className='d-flex justify-content-between'>
                              <div className='location'>
                                <p><CiLocationOn /> <span>INDIA</span></p>
                              </div>
                              <div className='price'>
                                <p>${item.price}
                                  <FaBookmark />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            )}
          </Col>
          <Col md={3}>
            <Card className="mb-5">
              <div className="filter-card mb-3">
                <Card.Body>
                  <h5>Sort By Results</h5>
                  {/* Add sorting options */}
                  <Form.Select>
                    <option value="latest">Latest Products</option>
                  </Form.Select>
                </Card.Body>
              </div>
              <div className="filter-card mb-3">
                <Card.Body>
                  <h5>Types of laptop</h5>
                  {/* Add laptop type options */}
                  <Form.Select>
                    {laptopTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                </Card.Body>
              </div>

              <div className="filter-card mb-3">
                <Card.Body>
                  <h5>Filter ads by</h5>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                      <label class="form-check-label" for="flexRadioDefault1">
                        Featured
                      </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked/>
                      <label class="form-check-label" for="flexRadioDefault2">
                        Latest
                      </label>
                  </div>

                  <h5 className="mt-4">Filter By Price</h5>
                  <div class=" row d-flex">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="maxPrice">Max Price: </label>
                        <input type="number" class="form-control" id="" placeholder="Max" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="minPrice">Min Price:</label>
                        <input type="number" class="form-control" id="" placeholder="Min" />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Card>
            <Card className="filter-card text-center">
              <h5 className="product-categories mt-5">More Categories</h5>
              <Container>
                <div className="container-fluid mt-5">
                  <div className="row">
                    {categories.slice(0, showAllCategories ? categories.length : 6).map(category => (
                      <div className="col-12" key={category.id}>
                        <Link to={`/productslist?id=${category.id}`} className=" card1">
                          <div className="cateory_div">
                            <div className=" d-flex justify-content-between">

                              <div className="d-flex">
                                {category.image && (
                                  <img src={category.image} alt={category.name} className="image-icon" />
                                )}
                                <p className="">{category.name}</p>
                              </div>
                              <div className="">
                                <p>{category.productcount}</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                  {!showAllCategories && categories.length > 6 && (
                    <div className="text-start mb-3 mt-3">
                      <Button className="button-product" onClick={() => setShowAllCategories(true)}>Show More</Button>
                    </div>
                  )}
                </div>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductList;
