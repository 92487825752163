import React, { useState, useEffect } from 'react';
import { MdAddLocation } from "react-icons/md";
import { Row, Col, Card, Container, Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { FaRegEdit } from "react-icons/fa";
import "./Address.css";
import ProfileSideNav from './ProfileSideNav';
import { useLocation } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";

const Address = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const from = searchParams.get("from");
    const [showForm, setShowForm] = useState(false);
    const [authorId, setAuthorId] = useState(localStorage.getItem('author_id') || "");
    const [editShowForm, setEditShowForm] = useState(false);

    const [submittedData, setSubmittedData] = useState([]);
    const [appendData, setData] = useState([]);
    useEffect(() => {
        if (!showForm) {
            fetchSavedAddresses();
        }
    }, [showForm]);

    const fetchSavedAddresses = async () => {
        try {
            const url = `https://fashionapi.codingster.in/List_Address?authorid=${authorId}`;
            const response = await axios.get(url);
            const responseData = response.data;
            debugger;
            if (responseData != null) {
                setSubmittedData(responseData);
            } else {
                toast.error(responseData.responsemessage || "Error fetching saved addresses");
            }
        } catch (error) {
            toast.error("An error occurred:", error.message);
            console.error("Error:", error);
        }
    };

    const deleteadd = async (id) => {
        debugger;
        try {
            const response = await axios.post('https://fashionapi.codingster.in/Delete_Address', {
                id: id,
                authorId: authorId,
                usertype: 4,
            }, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }

            });
            const responseData = response.data;

            if (responseData.responseCode == 0) {
                const updatedAddresses = submittedData.filter(address => address.id !== id);
                setSubmittedData(updatedAddresses);
                toast.success(responseData.responsemessage);
            } else {
                toast.error(responseData.responsemessage);
            }
        } catch (error) {
            // Handle error
            toast.error("An error occurred:", error.message);
            console.error("Error:", error);
        }
    };

    const setDefaultAddress = async (id) => {
        try {
            const response = await axios.post('https://fashionapi.codingster.in/Defaultaddress', {
                id: id,
                authorId: authorId,
                usertype: 4,
            }, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            });

            const responseData = response.data;

            if (responseData.responseCode == 0) {
                const selectedIndex = submittedData.findIndex(address => address.id === id);
                const updatedAddresses = submittedData.map((address, index) => ({
                    ...address,
                    isprimary: index === selectedIndex
                }));
                setSubmittedData(updatedAddresses);
                toast.success("Address set as default successfully");
                setTimeout(() => {
                    if (from) {
                        window.location.href = "/delivery"
                    }
                }, 4000);

            } else {
                toast.error(responseData.responsemessage);
            }
        } catch (error) {
            toast.error("An error occurred:", error.message);
            console.error("Error:", error);
        }
    };
    const addaddres = async () => {
        if(from){
            window.location.href="/addaddress?from=delivery"
        }
        else{
            window.location.href="/addaddress"
        }
    }

    return (
        <Container className='mt-5'>
            <Row>
                <Col md={4}>
                    <ProfileSideNav />
                </Col >
                <Col md={8} className='mt-5'>
                    <h5 className='text-uppercase'>Delivery Address</h5>
                    <Row className='rightsidedata'>
                        <Col md={4}>
                            <Link onClick={addaddres}>
                                <div className="custom-col">
                                    <div className="card-profile">
                                        <div className="card-body">
                                            <MdAddLocation className="profileicon" />
                                            <h5 className="card-title">Add New Address</h5>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        {submittedData && submittedData.map((address, index) => (
                            <Col md={4} key={index}>
                                <Card className="mb-5">
                                    <Card.Body>
                                        <Card.Text>
                                            <div className='d-flex'>
                                                <input type='radio' onChange={() => setDefaultAddress(address.id)} checked={address.isprimary} />
                                                <p className='default'>Default Address</p>
                                            </div>
                                            <strong>Name:</strong> {address.name}<br />
                                            <strong>Phone:</strong> {address.phone}<br />
                                            <strong>Address:</strong> {address.address}<br />
                                            <strong>City:</strong> {address.city}<br />
                                            <strong>State:</strong> {address.state}<br />
                                            <strong>Pincode:</strong> {address.pincode}<br />
                                            <strong>Area:</strong> {address.area}<br />
                                        </Card.Text>
                                        <div className='d-flex justify-content-end'>
                                            <Button className='deletebtn mt-1' onClick={() => deleteadd(address.id)}><MdDeleteOutline /></Button>
                                            <Link to={`/EditAddress?id=${address.id}`} className='mb-0 editbtn' ><FaRegEdit /></Link>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <ToastContainer />
            </Row >
        </Container>
    );
};

export default Address;
