import React, { useState } from 'react';
import { Accordion, Card, Button, Col, Row, Container, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./Faqspages.css";
import axios from "axios";

const Faqspages = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Make an HTTP POST request to the specified URL
            await fetch('https://fashionapi.codingster.in/Create_ContactForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams(formData).toString()
            });
            alert('Form submitted successfully!');
            // Reset form data after successful submission
            setFormData({
                name: '',
                email: '',
                message: ''
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form. Please try again later.');
        }
    };

    // Function to handle form input changes
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Container fluid className="p-0">
            <div className="FAQContainer">
                <div className="FAQItem">
                    <h3>FAQ Page</h3>
                </div>
            </div>
            <Container>
                <Row>
                    <Col md={12} >
                        <div>
                            <Row>
                                <Col md={3} className='mt-4'>
                                    <div className="topics-card">
                                        <Card>
                                            <Card.Body>
                                                <h5>Few Topics</h5>
                                                <Card.Title>Our Company</Card.Title>
                                                <Card.Title>Privacy Policy</Card.Title>
                                                <Card.Title>Refund Policy</Card.Title>
                                                <Card.Title>Price Plans</Card.Title>
                                            </Card.Body>
                                        </Card>
                                        <Card>
                                            <Card.Body>
                                                <h5>Ask Anything</h5>
                                                {/* Form for user input */}
                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Group className="mb-3" controlId="formName">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter your name"
                                                            name="name"
                                                            value={formData.name}
                                                            onChange={handleInputChange}
                                                            className="input-field"
                                                            required
                                                            style={{ border: "1px solid black" }} // Add inline style for border
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formEmail">
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Enter email"
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                            className="input-field"
                                                            required
                                                            style={{ border: "1px solid black" }} // Add inline style for border
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formMessage">
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            placeholder="Enter your message"
                                                            name="message"
                                                            value={formData.message}
                                                            onChange={handleInputChange}
                                                            className="input-field"
                                                            required
                                                            style={{ border: "1px solid black" }} // Add inline style for border
                                                        />
                                                    </Form.Group>
                                                    <Button type="submit" className="submit-button">
                                                        Submit <FontAwesomeIcon icon={faArrowRight} />
                                                    </Button>
                                                </Form>

                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>
                                <Col md={9} className='mt-4'>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="faq-accordion-header">Accordion Item #1</Accordion.Header>
                                            <Accordion.Body className="faq-accordion-body">
                                                This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header className="faq-accordion-header">Accordion Item #2</Accordion.Header>
                                            <Accordion.Body className="faq-accordion-body">
                                                This is the second item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header className="faq-accordion-header">Accordion Item #3</Accordion.Header>
                                            <Accordion.Body className="faq-accordion-body">
                                                This is the third item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header className="faq-accordion-header">Accordion Item #4</Accordion.Header>
                                            <Accordion.Body className="faq-accordion-body">
                                                This is the fourth item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Faqspages;
