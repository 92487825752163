import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Container, Row, Col } from 'react-bootstrap';
import "./Css/Delivery.css";
import { Link } from 'react-router-dom';
import OrderDetails from './OrderDetails';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeliveryAddress = () => {
  const [loading, setLoading] = useState(true);
  const [cartDetails, setCartDetails] = useState(null);
  const [couponamount, setCouponamount]= useState({})
  const [primaryAddress, setPrimaryAddress] = useState(null);
  debugger;
  const cartId = localStorage.getItem("cart_id");
  const authorId = localStorage.getItem("author_id");
  useEffect(() => {

    getCartDetails();
  }, []);
  const getCartDetails = async () => {
    debugger;
    try {
      const response = await axios.get(`https://fashionapi.codingster.in/Cartamount?cart_id=${cartId}`);
      setCartDetails(response.data);
    } catch (error) {
      console.error("Error fetching cart details:", error);
      setError("Error fetching cart details");
    }
  };


  if (primaryAddress != null) {
    localStorage.setItem("addressbookid", primaryAddress.id);
  }
  const [error, setError] = useState(null);
  const [selectedCartItem, setSelectedCartItem] = useState(null);

  useEffect(() => {
    const fetchPrimaryAddress = async () => {
      try {
        const authorId = localStorage.getItem('author_id');

        const response = await axios.get(
          `https://fashionapi.codingster.in/List_Address?authorid=${authorId}`
        );
        if (response.status === 200) {
          const primaryAddress = response.data.find(address => address.isprimary === true);
          setPrimaryAddress(primaryAddress);

        } else {
          setError('Failed to fetch delivery addresses');
        }
      } catch (error) {
        console.error('Error fetching delivery addresses:', error);
        setError('Error fetching delivery addresses');
      } finally {
        setLoading(false);
      }
    };

    fetchPrimaryAddress();
  }, []);



  const handleProceedToShipping = async () => {
    debugger;
    const cartId = localStorage.getItem("cart_id");
    const authorId = localStorage.getItem("author_id");
    const addressBookId = localStorage.getItem('addressbookid');
  
    if (!authorId) {
      toast.error("Please Login");
      window.location.href = "login.html";
      return;
    }
  
    if (!addressBookId) {
      toast.error("Add Address");
      window.location.href = "address.html";
      return;
    }
  
    if (cartId && authorId && addressBookId) {
      const cb = {
        authorid: authorId,
        totalamount: cartDetails.price,
        addressbook_id: addressBookId,
        cart_id: cartId,
        transaction_id: null,
        usertype: 4
      };
      const formData = new URLSearchParams(cb);
      const response = await fetch('https://fashionapi.codingster.in/Create_Bill', {
        method: 'POST',
        body:formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
      },
        //body: JSON.stringify(cb),
      });
      const responseData = await response.json();
      if (responseData.responseCode === 0) {
        debugger;
        localStorage.removeItem(cartId);
        toast.success('Order placed successfully!');
        const timeout = setTimeout(() => {
          window.location.href = "/myorders";
        }, 5000);
        return () => clearTimeout(timeout);
      } else {
        toast.error(responseData.responsemessage);
      }
    }
  };
  //------------------check login address-------------------//
const checklogin = async () => {
  if(authorId){
    window.location.href="/address?from=delivery"
  }
  else{
    window.location.href="/login"
  }
}

  
  return (
    <Container>
      <Row className='mb-5'>
        <Col md={9}>
          <div className="delivery-address-container">
            <h2 className="delivery-address-heading"><FaMapMarkerAlt /> Delivery Address</h2>
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="text-start delivery-address-error" onClick={checklogin}>add Address</p>
            ) : (
              primaryAddress ? (
                <div>
                  <p className='delivery-default'>Default</p>

                  <h4 className="delivery-address-name">{primaryAddress.name}</h4>
                  <p className="delivery-address-details">{primaryAddress.address}</p>
                  <p className="delivery-address-details">{primaryAddress.city}, {primaryAddress.state} - {primaryAddress.pincode}</p>
                  <p className="delivery-address-details">Phone: {primaryAddress.phone}</p>
                  <Link to="/address?from=delivery" className="button-product">
                    Change Address
                  </Link>
                </div>
              ) : (
                <Link to="/address?from=delivery" className="delivery-address-error px-5">
                  Add Address
                </Link>
                // <p className="delivery-address-error"></p>
              )
            )}
          </div>
        </Col>
        <Col md={3}>

          <OrderDetails
            cartamount={cartDetails}
            coupon={couponamount}
          />
         
          <Link  className="proceed-to-shipping-link" onClick={handleProceedToShipping}>
            <button className="proceed-to-shipping-btn" id="proceedbtn">
              Place Order
            </button>
          </Link>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default DeliveryAddress;
