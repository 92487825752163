import React, { useState, useEffect } from "react";
import "./BrowserItems.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const BrowserItems = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetch("https://fashionapi.codingster.in/List_Category")
            .then(response => response.json())
            .then(data => setCategories(data))
            .catch(error => console.error("Error fetching categories:", error));
    }, []);

    return (
        <Container>
            <div className="container-fluid mt-5">
                <p className="title_small mb-0">Hand-picked Category</p>
                <h1 className="title mb-4">
                    Browse items by <span className="category">category</span>
                </h1>
                <div className="row">
                    {categories.map(category => (
                        <div className="col-md-3" key={category.id}>
                            <Link to={`/productslist?cat=${category.name}`}  className="card card1">
                                <div className="card-body">
                                    <div className="card-content d-flex justify-content-between">
                                        <div className="card-text">
                                            <h5 className="card-title">{category.name}</h5>
                                            {/* Assuming the description is available in the API response */}
                                            <p>{category.description}</p>
                                        </div>
                                        {category.image && (
                                            <img src={category.image} alt={category.name} className="card-icon" />
                                        )}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
}






























































export default BrowserItems;
