import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Signin from './components/Signin';
import ScrollToTop from './components/ScrollTop';
import Home from './components/Home';
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import ProductList from "./components/ProductList";
import ProductDetails from "./components/ProductDetails";
import Footer from './components/Footer';
import Faqspages from './components/Faqspages';
import Contact from './components/Contact';
import Login from './components/Login';
import Profile from './components/Profile';
import Address from './components/Address';
import Checkout from './components/Checkout';
import Cart from './components/Cart';
import AddAddress from './components/AddAddress';
import EdtiAddress from './components/EditAddress';
import Delivery from './components/Delivery';
import ShowProducts from './components/ShowProducts';
import ChangePassword from './components/ChangePassword';
import Customersupport from './components/Customersupport';
import MyOrders from './components/MyOrders';
import ForgotPassword from './components/ForgotPassword';
import OTPVerification from './components/OTP';
import Aboutus from './components/Aboutus';
import MyOrderDetails from './components/MyOrderDetails';
import Loader from './components/Loader';

function App() {
  const userName = localStorage.getItem('userName');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    setIsLoading(true);
    fetchDataFromAPI().then(() => {

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    });
  }, []);

  const fetchDataFromAPI = () => {

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    });
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Header userName={userName} />
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/home" element={<Home isLoading={isLoading} />} />
          <Route path="/" element={<Home />} />
          <Route path="/productslist/:categoryId" element={<ProductList isLoading={isLoading} />} />
          <Route path="/productdetails" element={<ProductDetails />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/productslist" element={<ProductList />} />
          <Route path="/productdetails" element={<ProductDetails />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/login" element={<Login />} />
          <Route path="/faqspages" element={<Faqspages />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/address" element={<Address />} />
          <Route path="/addaddress" element={<AddAddress />} />
          <Route path="/EditAddress" element={<EdtiAddress />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/customercare" element={<Customersupport />} />
          <Route path="/products" element={<ShowProducts />} />
          <Route path="/myorders" element={<MyOrders />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/otp" element={<OTPVerification />} />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/AboutUs" element={<Aboutus />} />
          <Route path='/myorderdetails' element={<MyOrderDetails />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
