import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Signin.css";


debugger;
function Sign({ onSignUpSuccess }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    Phone: "",
    address: "",
    usertype: "4",
  });
  const [passwordError, setPasswordError] = useState("");
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [phoneError, setPhoneError] = useState(""); // Define phoneError state

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "Phone") {
      if (value.length !== 10) {
        setPhoneError("Mobile number must be 10 digits.");
      } else {
        setPhoneError("");
      }
    }

    if (name === "password") {
      const passwordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      const isValidPassword = passwordRegex.test(value);

      if (!isValidPassword) {
        const suggestions = [];

        // Check for length
        if (value.length < 8) {
          suggestions.push("Password should be at least 8 characters long.");
        }

        // Check for alphanumeric characters
        if (!/[A-Za-z]/.test(value)) {
          suggestions.push("Include at least one letter (a-z or A-Z).");
        }

        if (!/\d/.test(value)) {
          suggestions.push("Include at least one digit (0-9).");
        }

        // Check for special characters
        if (!/[@$!%*#?&]/.test(value)) {
          suggestions.push(
            "Include at least one special character (@, $, !, %, *, #, ?, or &)."
          );
        }

        setPasswordError(suggestions.join(" "));
      } else {
        setPasswordError("");
      }
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Check if any field is empty
    for (const key in formData) {
      if (!formData[key]) {
        toast.error(`${key} is required.`);
        return;
      }
    }

    // Validate mobile number length
    if (formData.Phone.length !== 10) {
      setMobileNumberError("Mobile number must be 10 digits.");
      return;
    }

    const url = "https://fashionapi.codingster.in/Create_User";
    const data = new URLSearchParams(formData);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });



      debugger;



      const responseData = await response.json();
      debugger;
      if (responseData.responseCode == 0) {
        toast.success(responseData.responsemessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });


        // Delay before redirecting
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000); // Redirect after 3 seconds

        // Clear form data
        setFormData({
          name: "",
          email: "",
          password: "",
          Phone: "",
          address: "",
          usertype: "4",
        });
      } else {
        toast.error(
          responseData.responsemessage,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } catch (error) {
      toast.error("An error occurred during registration.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error("Fetch error:", error);
    }
  };

  return (
    <Container fluid className="custom-container">
      <Row className="login">
        <Col md={6} className="form-column">
          <div className="form-wrapper">
            <Form className="login-pages" onSubmit={handleSignUp}>
              <p>Create a new account</p>
              <Form.Group controlId="formBasicName">
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formBasicarea">
                <Form.Control
                  type="text"
                  placeholder="Area"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formBasicnbr">
                <Form.Control
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  placeholder="XXXXXXXXXX"
                  name="Phone"
                  value={formData.Phone}
                  onChange={handleInputChange}
                />
                {mobileNumberError && (
                  <Form.Text className="text-danger">
                    {mobileNumberError}
                  </Form.Text>
                )}
                {phoneError && (
                  <Form.Text className="text-danger">
                    {phoneError}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                {passwordError && (
                  <Form.Text className="text-danger">
                    {passwordError}
                  </Form.Text>
                )}
              </Form.Group>
              
              <Form.Group controlId="formBasicTerms">
                <label className="d-flex">
                  <input
                    type="checkbox"
                    id="terms"
                    className="check_box"
                    checked={isTermsChecked}
                    onChange={() => setIsTermsChecked(!isTermsChecked)}
                  />
                  I agree to the terms and conditions{"   "}
                  <Link to="/termsofuse"></Link>
                </label>
              </Form.Group>

              <Button
                className="form-control btn btn-success mt-2"
                type="submit"
                disabled={!isTermsChecked}
              >
                Sign Up
              </Button>

              <div className="form-links">
                <span> Already have an account? </span>
                <Link className="small text-muted" to="/login">
                  <span className="login-link">Login here</span>
                </Link>
              </div>
            </Form>
          </div>
        </Col>
        <Col md={6} className="image-column mt-5">
          <img
            src="https://img.freepik.com/free-vector/tablet-login-concept-illustration_114360-7863.jpg"
            alt="Login Image"
            className="img-fluid"
          />
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}
export default Sign;
