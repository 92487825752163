import React, { useState, useEffect } from "react";
import './FeaturedProducts.css';
import { Container, Row, Col } from 'react-bootstrap';
import { FiClock } from "react-icons/fi";
import { CiLocationOn } from "react-icons/ci";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";

const FeaturedProducts = () => {
    debugger;
    const [data, setData] = useState([]);
    debugger;
    const [activeButton, setActiveButton] = useState('featured');

    useEffect(() => {
        // Fetch default Featured Products API
        fetchFeaturedProducts();
    }, []);

    // Function to fetch Featured Products API
    const fetchFeaturedProducts = () => {
        fetch("https://fashionapi.codingster.in/FeaturedProduct")
            .then(response => response.json())
            .then(data => setData(data.slice(0, 4)))
            .catch(error => console.error("Error fetching products:", error));
    };

    // Function to fetch Latest Products API
    const fetchLatestProducts = () => {
        debugger;
        fetch("https://fashionapi.codingster.in/LatestProduct")
            .then(response => response.json())
            
            .then(data => setData(data.slice(0, 4)))
            
            .catch(error => console.error("Error fetching products:", error));
    };

    // Function to handle button clicks
    const handleButtonClick = (apiType) => {
        if (apiType === 'featured') {
            fetchFeaturedProducts();
        } else if (apiType === 'latest') {
            fetchLatestProducts();
        }
        // Update active button state
        setActiveButton(apiType);
    };
    const truncateDescription = (description) => {
        if (description.length > 30) {
            return `${description.substring(0, 30)}...`;
        }
        return description;
    };
    const productname = (name) => {
        if (name.length > 20) {
            return `${name.substring(0, 20)}...`;
        }
        return name;
    };
    return (
        <div className="featured-products">
            <div className="text-div1 text-center mt-5">
                <p className='title_small mb-0'>Recently Added</p>
            </div>
            <div className="text-div text-center">
                <h2>Featured</h2>
                <h1 className="title">
                    <span className="category9">Products</span>
                </h1>
            </div>

            <div className="buttons-container">
                <button className={`button ${activeButton === 'featured' ? 'active' : ''}`} onClick={() => handleButtonClick('featured')} id='featured'>Featured</button>
                <button className={`button ${activeButton === 'latest' ? 'active' : ''}`} onClick={() => handleButtonClick('latest')} id='latest'>Latest Products</button>
            </div>
            <div className="featured-products">
                <Container>
                    <Row id='products'>
                        {data.map((item, index) => (
                            <Col md={3} key={index}>
                                <Link to={`/productdetails?productId=${item.id}`}>
                                <div className="custom-card card" >
                                    <img src={item.display_image} alt="" />
                                    <div className="card-content">
                                        {item.discount > 0 && (
                                            <p className="productdiscount">{item.discount}% OFF</p>
                                        )}
                                        <div className="card-description">
                                            <div className='d-flex justify-content-between'>
                                                <p className='categoryame text-uppercase'>{productname(item.categoryname)}</p>
                                                <p className='clock'><FiClock />1 Day ago</p>
                                            </div>
                                            <div className='productname'>

                                                <p>{productname(item.name)}</p>
                                            </div>
                                            <div className='prductdata'>
                                            {/* <p>{item.description}</p> */}
                                                <p>{truncateDescription(item.description)}</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div className='location'>
                                                    <p><CiLocationOn /> <span>{(item.area)}</span></p>
                                                </div>
                                                <div className='price'>
                                                    <p>${item.price}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </Container>

                <div className='container text-center mt-5 morebtn'>
                    <Link to="/productslist" className="custom-button">
                        More Products <span className="bi bi-arrow-right"><FaArrowRight /></span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default FeaturedProducts;
