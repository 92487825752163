import React from "react";
import { Card, Row, Col, Accordion, Image, Button, Container } from "react-bootstrap";
import logo8 from "./Images/shipping.png";
import logo9 from "./Images/Rectangle 345.png";
import logo98 from "./Images/bg.png";
import "./Faqs.css";
import { Link } from "react-router-dom";

const Faqs = () => {
  return (
    <>
      <Container fluid className="faqmaindiv">
        <Container>
          <Card className=" faqcard">
            <Row>
              <Col md={2} className="d-flex align-items-center justify-content-center">
                <Image src={logo8} alt="Shipping Icon" fluid />
              </Col>
              <Col md={6} className="d-flex align-items-center">
                <Card.Body>
                  <Card.Title className="mb-3 faqthead">Start loving CAPTAIN CART</Card.Title>
                  <Card.Text className="faqtext">
                    Interest in buying at CAPTAIN CART is higher than ever before! It is good for the environment and for your daily life.
                  </Card.Text>
                  <Link to={`/productslist`} className="faq-button text-uppercase">Explore products</Link>
                </Card.Body>
              </Col>
              <Col md={4}>
                <Image src={logo9} alt="Photo" className="faqimg" />
              </Col>
            </Row>
          </Card>
        </Container>

        <Container className="faqs-container">

        </Container>
        <Container>
          <Row className="faqdowncontentdivright">
            <Col md={6} >
              <Image src={logo98} alt="Photo" fluid />
            </Col>
            <Col md={6}>
              <div className="mt-5">
                <p className="title_small mb-0">FAQ & Answers</p>
                <h3 className="mb-4 getevery">Get every <span className="category">answers</span></h3>
                <Accordion defaultActiveKey="0" className="mb-4">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="faq-accordion-header">What services does it offer to users?</Accordion.Header>
                    <Accordion.Body className="faq-accordion-body">
                      Please note that specific details about listbnb.com may change over time, so it's a good idea to visit their official website or contact their customer support for the most up-to-date information.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="1" className="mb-4">
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="faq-accordion-header">Either for listing items or completing transactions?</Accordion.Header>
                    <Accordion.Body className="faq-accordion-body">
                      Please note that specific details about listbnb.com may change over time, so it's a good idea to visit their official website or contact their customer support for the most up-to-date information.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="1" className="mb-4">
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="faq-accordion-header">When buying and selling items?</Accordion.Header>
                    <Accordion.Body className="faq-accordion-body">
                      Please note that specific details about listbnb.com may change over time, so it's a good idea to visit their official website or contact their customer support for the most up-to-date information.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="1" className="mb-4">
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="faq-accordion-header">What services does it offer to users?</Accordion.Header>
                    <Accordion.Body className="faq-accordion-body">
                      Please note that specific details about listbnb.com may change over time, so it's a good idea to visit their official website or contact their customer support for the most up-to-date information.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="1" className="mb-4">
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="faq-accordion-header">Is it limited to a specific user group?</Accordion.Header>
                    <Accordion.Body className="faq-accordion-body">
                      Please note that specific details about listbnb.com may change over time, so it's a good idea to visit their official website or contact their customer support for the most up-to-date information.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="text-end">
                  <Link to='/faqspages' className="relatedfaqs">30+ <span>more related question</span> </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
        <hr/>
        </Container>
      </Container>
      





    </>
  );
};

export default Faqs;
